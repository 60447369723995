import styled from 'styled-components';

export const Styled404 = styled.section`
  padding: 32px 0;
	color: ${({ theme }) => theme.colors.sapphireBlue};

  @media (min-width: 1024px) {
    padding: 48px 0;
  }

  p:first-child {
    margin-bottom: 10px;
  }

	p:last-child {
    margin-top: 10px;
  }
`;
