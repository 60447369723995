import React from "react"
import { Container, Row, Col } from 'react-bootstrap';
import Layout from "../components/layout";
import SEO from "../components/seo";
import { Styled404 } from '../styles/404Styles';

const NotFoundPage = () => (
	<Layout>
		<Container>
			<Styled404>
				<SEO title="404: Not found" />
				<h1>404: Not Found</h1>
				<p>You just hit a route that doesn&#39;t exist... try again!</p>
			</Styled404>
		</Container>
	</Layout>
)

export default NotFoundPage
